<template>
  <div class="git-redirect"><app-header title="Git" :hide-back="true"/></div>
</template>

<script>
  import AppHeader from '../components/AppHeader';

  export default {
    components: {
      AppHeader
    },
    data: () => ({
      loading: false,
      quotes: [],
      randomQuote: null,
    }),
    methods: {
    },
    created() {
      const gitlabToken = localStorage.getItem('gitlabToken');
      if(!gitlabToken) {
        this.$router.push({name: 'dashboard'}).catch(err => {})
      }

      window.location = 'https://gitlab.hyperion-corporation.de/users/auth/jwt/callback?jwt=' + gitlabToken;
    }
  }
</script>
